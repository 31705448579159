import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql } from 'gatsby';
import componentFinder from '../util/componentFinder';

const useStyles = props =>
  makeStyles(theme => ({
    background: {
      // background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.10) 50%, rgba(0, 0, 0, 0.65) 100%)`,
      backgroundRepeat: 'repeat',
      backgroundPosition: 'top',
      backgroundSize: 'cover',
      backgroundAttachment: props.data.parallax,
      [theme.breakpoints.down('md')]: {
        backgroundAttachment: 'scroll !important',
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: props.data.heroHeight,
      justifyContent: 'flex-end',
    },
    border: {
      borderTop:'double 5px #B90605',
      borderBottom:'double 5px #B90605',
    },
    title: {
      fontSize: '1.73995rem',
      fontWeight:600,
    },
    mainText: {
      textTransform: 'uppercase',
      fontSize: '1.2512rem',
      fontWeight:300,
    },
    overlay: {
      backgroundColor: 'white',
      marginBottom: '5rem',
      [theme.breakpoints.down('md')]: {
        marginBottom: '0rem',
      },
    },
  }));


let getDirectionFromOrientation = orientation => {
  switch (orientation) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    default:
      return 'flex-end';
  }
};

const Hero = props => {
  const data = props.data[props.componentId];

  const classes = useStyles({ ...props, data })();

  const direction = getDirectionFromOrientation(data.orientation);

  
  return (
    <BackgroundImage
      Tag="section"
      className={classes.background}
      fluid={data.image.fluid}
      backgroundColor={`#040e18`}
      id="fullscreenbg"
      role="img"
      aria-label="Fullscreen Background"
      preserveStackingContext={true}
    >
      <Container maxWidth="lg">
        <Grid
          className={classes.root}
          item
          container
          alignItems={direction}
        >
          <Grid item sm={12} md={6} lg={6}></Grid>
          <Grid
            className={classes.overlay}
            item
            sm={12}
            md={6}
            lg={6}
          >
            <Grid>
                <Box className={classes.border} p={2}>
                  <Typography className={classes.title} component="h3">
                    {data.overlayTitle}
                  </Typography>
                  <Typography className={classes.mainText} component="span" variant="body2">
                    {documentToReactComponents(data.overlayText.json)}
                  </Typography>
                </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </BackgroundImage>
  );
};

export default Hero;
